<template>
  <div class="c-login">
    <div class="c-login__wrapper">
      <VHeading header="h1" class="c-login__heading">
        {{ $t('LOGIN_HEADING') }}
      </VHeading>
      <div class="c-login__cols">
        <div class="c-login__col">
          <p class="c-login__text" v-html="$t('LOGIN_TEXT')" />
          <TheButton
            class="c-login__button"
            @click.native="login"
          >
            {{ $t('LOGIN_BUTTON') }}
          </TheButton>
        </div>
        <div class="c-login__col">
          <img src="/img/welcome-image.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import TheButton from '@forlagshuset/v-button'
import VHeading from '@forlagshuset/v-heading'
import auth from '@/auth'

export default {
  components: {
    TheButton,
    VHeading
  },

  setup (props, vm) {
    onMounted(() => {
      auth.checkToken(vm.root.$route.query.token ? `?token=${vm.root.$route.query.token}` : '')
        .then(user => {
          vm.root.$store.dispatch('user/setToken')
          vm.root.$store.dispatch('user/setUser', user)
          vm.root.$router.push('/')
        })
    })

    const login = () => auth.authorize({
      'redirectUrl': window.location.origin
    })

    return {
      login
    }
  }
}
</script>

<style lang="scss" scoped>
.c-login {
  text-align: left;
}

.c-login__text {
  font-size: 1.4rem;
  line-height: 1.7;
}

.c-login__button {
  margin-top: 2rem;
  background: $color-brand;
  color: $color-white;
  width: 25rem;
  padding: 1.3rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.c-login__wrapper {
  max-width: 1280px;
  margin: 3rem auto 0;
  padding: 0 1rem;
}

.c-login__cols {
  margin-top: 1rem;
  display: flex;
}

.c-login__col {
  padding: 1rem;
  width: 50%;
}

.c-login__heading {
  font-size: 1.8rem;
  font-weight: 600;
}
</style>